/**
 * First we will load all of this project's JavaScript dependencies which
 * includes React and other helpers. It's a great starting point while
 * building robust, powerful web applications using React + Laravel.
 */

import 'app/bootstrap';
import * as ReactDOM from 'react-dom';

/**
 * Next, we will create a fresh React component instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import Containers from 'app/containers';

import routes from './routes';

document.addEventListener('DOMContentLoaded', () => {
  // if an app wrapper is present, mount routes on it
  const app = document.getElementById('react-app');
  if (app) {
    ReactDOM.render(routes, app);
  }

  // also process adhoc mounting
  Containers.mount();
});

/**
 * URLs changing in our app can feel very slow.
 *
 * When we change URLs we want immediate feedback that something happened when
 * the user clicked, so we hide the main react-app when a window event changes.
 *
 * This problem should reduce as we move towards being fully reactified.
 */
window.addEventListener(
  'beforeunload',
  function (e) {
    const app = document.getElementById('react-app');

    if (app) {
      // Element.style.visibility = 'hidden' causes an error in MS Edge
      // Using setAttribute instead as we dont care about preserving existing styles
      app.setAttribute('style', 'visibility: hidden');
    }
  },
  false
);
